<template>
  <div class="overlay" @click.self="closeModal">
    <div class="modal">
      <div class="modal-toggle" @click="closeModal" />
      <div class="modal-content">
        <Richtext :content="getModalContent?.mediaContent" />
      </div>
      <div v-if="previewImageUrl" class="modal-image-box">
        <div
          v-for="(item, index) in getModalContent?.media"
          :key="index"
          class="modal-image"
          :style="'background-image: url(' + item.fields?.file?.url + ')'"
          @click="showImage(item.fields?.file?.url)"
          @contextmenu.prevent
          :src="item.fields?.file?.url + '?q=10'"
        />
      </div>
    </div>
    <DetailedModalImage
      v-if="imageUrl?.length"
      :imageUrl="imageUrl"
      :close="closeDetailedImage"
    />
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
import Richtext from './Richtext';
import DetailedModalImage from './Detailed-Modal-Image.vue';

export default {
  name: 'modal-component',
  components: {
    Richtext,
    DetailedModalImage,
  },
  data() {
    return {
      imageUrl: null,
    };
  },
  computed: {
    ...mapGetters(['getModalContent']),

    previewImageUrl() {
      return (
        this.getModalContent?.displayImage?.fields?.file?.url ??
        this.getModalContent?.media[0]?.fields?.file?.url
      );
    },
  },
  methods: {
    ...mapMutations(['setModalState']),

    closeModal() {
      this.setModalState(false);
    },

    closeDetailedImage() {
      this.imageUrl = null;
    },

    showImage(url) {
      this.imageUrl = url;
    },
  },
};
</script>

<style lang="scss">
@import '@/assets/main.scss';

.modal {
  animation: appear 0.25s ease-in;
  background: $fulutu-white;
  display: flex;
  flex-direction: column;
  gap: 16px;
  position: absolute;
  padding: 16px 16px 24px;
  left: 50%;
  top: 94px;
  transform: translateX(-50%);
  max-height: calc(100% - 94px - 66px - 24px - 16px);
  width: calc(100% - 32px);
  overflow: auto;
  border-radius: 5px;

  @media (min-width: $md) {
    flex-direction: row;
    gap: 8px;
    padding: 16px 32px 24px;
    height: fit-content;
    max-height: 50%;
    width: 70%;
    top: 50%;
    transform: translate(-50%, -50%);
  }

  @media (min-width: $lg) {
    width: 1040px;
  }

  &-content {
    height: auto;

    @media (min-width: $md) {
      flex: 1;
      overflow: auto;
    }
  }

  &-image-box {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 16px;
    height: 100%;
    flex: 1;
    max-height: 100%;

    @media (min-width: $sm) {
      justify-content: flex-start;
    }

    @media (min-width: $md) {
      transform: translateX(0);
      max-width: 50%;
    }

    img {
      cursor: pointer;
      width: 100%;
    }

    .modal-image {
      border-radius: 5px;
      cursor: pointer;
      background-size: cover;
      background-position: center;
      filter: brightness(0.8);
      height: 0;
      width: 100%;
      padding-top: 100%;
      transition: all 0.3s ease;

      &:hover {
        filter: brightness(1);
      }
    }
  }

  &-link {
    color: $fulutu-black;
    font-weight: 600;
    text-decoration: underline;
    transition: $transition-all-ease-fast;

    &:hover {
      color: $fulutu-rose;
    }
  }

  &-toggle {
    border-radius: 5px;
    background: #fafafaaa;
    cursor: pointer;
    position: absolute;
    top: 24px;
    right: 24px;
    height: 24px;
    width: 24px;
    z-index: 1;

    &:before,
    &:after {
      content: '';
      cursor: pointer;
      background: $fulutu-black;
      position: absolute;
      left: 50%;
      top: 50%;
      transition: all 0.3s ease;
      transform: translate(-50%, -50%) rotate(45deg);
    }

    &:before {
      width: 20px;
      height: 4px;
    }
    &:after {
      height: 20px;
      width: 4px;
      right: 12px;
    }

    &:hover {
      &:before {
        left: 2px;
      }
      &:after {
        left: 10px;
      }

      &:before,
      &:after {
        background: rgba($fulutu-black, 1);
        transform: translateY(-50%) rotate(225deg);
      }
    }
  }
}

.overlay {
  animation: appear 0.5s ease-in;
  background: rgba($fulutu-black, 0.5);
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 110;

  .detailed-image {
    animation: fadeIn 0.5s cubic-bezier(0.165, 0.84, 0.44, 1) forwards;

    img {
      animation: modalContentFadeIn 1.5s cubic-bezier(0.165, 0.84, 0.44, 1)
        forwards;
      max-height: calc(100% - 94px - 66px);
      max-width: calc(100% - 16px);
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: 1;

      @media (min-width: $md) {
        max-height: calc(100% - 94px - 66px);
        max-height: calc(100% - 80px);
      }
    }

    .modal-toggle:before,
    .modal-toggle:after {
      background-color: white;
    }
  }
}
</style>
