<template>
  <div class="masonry-wall">
    <MasonryWall
      :items="media"
      :ssr-columns="1"
      :column-width="columnWidth"
      :gap="8"
    >
      <template #default="{ item }">
        <div
          v-if="item?.fields?.file?.url"
          class="masonry-card flex items-center justify-center"
        >
          <img
            :src="item?.fields?.file?.url"
            @click="() => handleClick(item?.fields?.file?.url)"
          />
        </div>
      </template>
    </MasonryWall>

    <DetailedModalImage
      v-if="imageUrl?.length"
      :imageUrl="imageUrl"
      :close="closeDetailedImage"
    />
  </div>
</template>

<script>
import MasonryWall from '@yeger/vue-masonry-wall';
import DetailedModalImage from './Detailed-Modal-Image.vue';

export default {
  name: 'text-image',
  components: {
    MasonryWall,
    DetailedModalImage,
  },
  props: {
    content: [Array, Object],
    isMini: Boolean,
  },
  data() {
    return {
      imageUrl: null,
    };
  },
  computed: {
    isMobile() {
      return window.innerWidth < 576;
    },
    columnWidth() {
      if (this.isMini) return 140;
      return this.isMobile ? 250 : 320;
    },
    media() {
      return this.isMobile && this.content?.mobileMedia
        ? this.content?.mobileMedia
        : this.content?.media;
    },
  },
  methods: {
    handleClick(url) {
      this.imageUrl = url;
    },

    closeDetailedImage() {
      this.imageUrl = null;
    },
  },
};
</script>

<style lang="scss">
@import '@/assets/main.scss';
.masonry-item {
  cursor: pointer;

  &:hover {
    img {
      transform: scale(1.1);
    }
  }

  .masonry-card {
    overflow: hidden;
    border-radius: 5px;

    img {
      animation: appear 0.5s ease-in;
      border-radius: 5px;
      width: 100%;
      transition: all 0.33s ease;
    }
  }
}
</style>
