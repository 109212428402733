<template>
  <div
    class="image-wrapper"
    :class="{
      'image-wrapper--blueish': content.borderType === 'Blueish',
    }"
  >
    <swiper
      v-if="content.media?.length > 1"
      :effect="'coverflow'"
      :grabCursor="content.media?.length > 1"
      slideToClickedSlide
      centeredSlides
      :navigation="content.media?.length > 1"
      :pagination="content.media.length > 1 ? { type: 'fraction' } : false"
      :slidesPerView="'auto'"
      :coverflowEffect="{
        rotate: 50,
        stretch: 0,
        depth: 100,
        modifier: 1,
      }"
      :modules="modules"
      :breakpoints="{
        320: {
          slidesPerView: 1,
          spaceBetween: 0,
        },
        769: {
          slidesPerView: 2,
          spaceBetween: 32,
        },
      }"
    >
      <swiper-slide
        v-for="image in content.media"
        :class="[
          'image-item',
          {
            'image-item--multi': content.media?.length > 1,
          },
        ]"
        :key="image.sys.id"
      >
        <img @contextmenu.prevent :src="image.fields?.file?.url + '?q=30'" />
      </swiper-slide>
    </swiper>
    <img
      v-else
      @contextmenu.prevent
      class="image-single"
      :class="{
        'image-smol': content.isSmol,
        'with-url': content.media[0]?.fields?.file?.url,
        'image-wrapper--clickable': content.redirectLink,
      }"
      :src="content.media[0]?.fields?.file?.url + '?q=30'"
      @click="clickHandler"
    />
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from 'swiper/vue';
import { Navigation, Pagination, EffectCoverflow } from 'swiper';
import 'swiper/css';
import 'swiper/css/effect-coverflow';
import 'swiper/css/effect-fade';
import 'swiper/css/navigation';

export default {
  name: 'Image-Container',
  components: {
    Swiper,
    SwiperSlide,
  },
  props: {
    content: [Array, Object],
  },
  setup() {
    return {
      modules: [Navigation, Pagination, EffectCoverflow],
    };
  },
  methods: {
    clickHandler() {
      if (this.content.redirectLink) {
        window.location.href = this.content.redirectLink;
      }
    },
  },
};
</script>

<style lang="scss">
@import '../assets/main.scss';

.image-wrapper {
  justify-content: center;
  align-items: center;
  display: flex;
  margin-bottom: $spacing-md;
  overflow: hidden;
  border-radius: 5px;

  @media (min-width: $md) {
    flex-direction: row;
  }

  img {
    border-radius: 5px;
  }

  &--clickable {
    cursor: pointer;
  }

  &--blueish {
    border: 8px solid #6e7ae7;
    border-radius: 20px;
    outline: 8px solid white;
    transition: all 0.3s ease;

    &:hover {
      border-color: $fulutu-blue-grey;
    }
  }

  .image-smol {
    width: 100%;

    @media (min-width: $sm) {
      width: 75%;
    }

    @media (min-width: $md) {
      width: 50%;
    }
  }
}

.image-item {
  &--multi {
    max-width: 350px;

    @media (min-width: $md) {
      max-width: 100%;
    }
  }

  img {
    width: 100%;
  }
}

.image-single {
  width: 100%;

  &.image-wrapper--clickable {
    transition: all 0.33s ease;

    &:hover {
      transform: scale(1.02);
    }
  }
}
</style>
