<template>
  <nav v-if="displayNavigation" class="nav">
    <a href="/" class="nav-logo">FULUTU</a>

    <div v-if="isDesktopView" class="nav-container">
      <template v-for="route in sortedRoutes" :key="route.title">
        <div class="nav-item">
          <router-link :to="route.url">{{ route.title }}</router-link>
          <div v-if="route.subPaths.length" class="nav-sub-item">
            <router-link
              v-for="subNavItem in route.subPaths"
              :to="subNavItem.url"
              :key="subNavItem.title"
            >
              {{ subNavItem.title }}
            </router-link>
          </div>
        </div>
      </template>
      <LanguageSwitcher
        :currentLanguage="getLanguage"
        @switch="switchLanguage"
      />
    </div>

    <div class="nav-mobile">
      <div
        id="nav-mobile-icon"
        class="nav-mobile-icon"
        @click="toggleBurgerMenu"
      >
        <span></span>
        <span></span>
        <span></span>
        <span></span>
      </div>

      <div id="nav-mobile-bar" class="nav-mobile-bar">
        <div class="nav-mobile-list">
          <div v-for="navItem in sortedRoutes" :key="navItem.title">
            <div class="nav-mobile-link">
              <router-link :to="navItem.url" @click="toggleBurgerMenu">
                {{ navItem.title }}
              </router-link>
              <div v-if="navItem.subPaths.length" class="nav-mobile-multi">
                <router-link
                  v-for="subNavItem in navItem.subPaths"
                  :to="subNavItem.url"
                  v-on:click="toggleBurgerMenu"
                  :key="subNavItem.title"
                >
                  {{ subNavItem.title }}
                </router-link>
              </div>
            </div>
          </div>
          <LanguageSwitcher
            :currentLanguage="getLanguage"
            @switch="switchLanguage"
          />
        </div>
      </div>
    </div>
  </nav>
</template>

<script>
import LanguageSwitcher from '@/components/LanguageSwitcher.vue';
import { mapGetters, mapMutations } from 'vuex';

export default {
  name: 'vcp-router',
  components: { LanguageSwitcher },
  data() {
    return {
      validFooterNavigation: ['/impressum', '/contact', '/imprint'],
      windowWidth: 0,
      displayNavigation: true,
    };
  },
  mounted() {
    this.$nextTick(function () {
      window.addEventListener('resize', this.getWindowWidth);
      this.getWindowWidth();
    });
  },
  computed: {
    ...mapGetters(['getRoutesEN', 'getRoutesDE', 'getLanguage']),
    isDesktopView() {
      return this.windowWidth >= 905;
    },
    sortedRoutes() {
      return this.sortRoutes();
    },
  },
  methods: {
    ...mapMutations(['setLanguage']),

    getWindowWidth() {
      this.windowWidth = document.documentElement.clientWidth;
    },

    sortRoutes() {
      const routes =
        this.getLanguage === 'de' ? this.getRoutesDE : this.getRoutesEN;
      const filteredRoutes = routes.filter(
        (t) => !this.validFooterNavigation.includes(t.url)
      );

      return filteredRoutes.sort((a, b) => {
        if (a.position === b.position) {
          return a.name?.localeCompare(b.name);
        }
        return a.position - b.position;
      });
    },

    sanitizeRouterLabel(route) {
      if (!route || route === '/') route = 'Home';
      route = route.replace('-', ' ').charAt(0).toUpperCase() + route.slice(1);
      return route;
    },

    toggleBurgerMenu() {
      const navBar = document.getElementById('nav-mobile-bar');
      const navIcon = document.getElementById('nav-mobile-icon');
      navBar.classList.toggle('nav-mobile-bar--open');
      navIcon.classList.toggle('nav-mobile-icon--open');
    },

    switchLanguage(lang) {
      this.setLanguage(lang);
    },
  },
};
</script>

<style lang="scss" scoped>
@import '../assets/main.scss';

nav {
  background: $fulutu-white;
  box-shadow: 0px 0px 6px -3px #050505;
  display: flex;
  justify-content: flex-end;
  top: 0;
  left: 0;
  padding: 0 32px;
  position: fixed;
  height: 94px;
  width: calc(100% - 64px);
  z-index: 20;
}

.nav {
  &-container {
    display: none;
    justify-content: flex-end;
    align-items: center;
    width: calc(100% - 32px);

    @media (min-width: $md) {
      display: flex;
    }
  }

  &-item {
    font-size: 24px;
    padding: 16px 12px;
    position: relative;
    text-transform: uppercase;

    > a {
      color: $fulutu-black;
      text-decoration: none;
      transition: $transition-all-ease-fast;
    }

    &:hover {
      .nav-sub-item {
        opacity: 1;
        visibility: visible;

        div {
          margin: 8px 0;
        }
      }

      > a {
        color: $fulutu-rose;
      }
    }

    .router-link-exact-active {
      color: $fulutu-rose;
    }
  }

  &-logo {
    color: $fulutu-black;
    font-family: 'Six Caps';
    font-size: 64px;
    text-decoration: none;
    position: absolute;
    left: 32px;
    top: 0;
    transition: $transition-all-ease-fast;
    z-index: 1;

    &:hover {
      color: $fulutu-rose;
    }
  }

  &-mobile {
    display: flex;
    align-items: center;

    @media (min-width: $md) {
      display: none;
    }

    &-icon {
      cursor: pointer;
      height: 40px;
      margin: 12px;
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
      transition: 0.2s ease-in-out;
      width: 60px;
      z-index: 21;

      span {
        background: $fulutu-grey;
        border-radius: 7px;
        display: block;
        left: 0;
        height: 4px;
        width: 100%;
        opacity: 1;
        position: fixed;
        transform: rotate(0deg);
        transition: 0.33s ease;

        &:first-child {
          top: 0;
        }
        &:nth-child(2),
        &:nth-child(3) {
          top: 18px;
        }
        &:last-child {
          top: 36px;
        }
      }

      &--open {
        position: fixed;
        top: 12px;
        right: 32px;

        span {
          background: $fulutu-white;
          &:first-child {
            top: 18px;
            transform: rotate(45deg);
            opacity: 0;
          }
          &:nth-child(2) {
            transform: rotate(-45deg);
          }
          &:nth-child(3) {
            transform: rotate(45deg);
          }
          &:last-child {
            top: 18px;
            transform: rotate(-45deg);
            opacity: 0;
          }
        }
      }
    }

    &-bar {
      align-items: center;
      background: rgba(17, 17, 17, 0.9);
      height: 100%;
      justify-content: center;
      left: 0;
      margin: 0;
      padding: 0;
      position: fixed;
      top: 0;
      transition: $transition-all-ease-fast;
      transform: translateX(-100%);
      width: 100%;
      z-index: 20;

      &--open {
        transform: translateX(0);
      }
    }
    &-list {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      height: 80%;
      padding: 0;
    }
    &-item {
      font-size: 30px;
      transition: all 0.33s ease;
    }

    &-link {
      font-size: $font-md;
      margin-bottom: 16px;
      text-align: center;

      &:not(:last-child) {
        margin-bottom: $spacing-lg;
      }

      a {
        color: $fulutu-white;
      }
    }

    &-multi {
      display: flex;
      flex-direction: column;
      align-items: center;

      a {
        margin-bottom: $spacing-md;

        &:first-child {
          margin-top: $spacing-md;
        }

        &:last-child {
          margin-bottom: 0;
        }
      }

      &-icon {
        filter: invert(1);
        width: 16px;
        transform: scaleY(-1) rotate(90deg);
      }
    }
  }

  &-sub-item {
    background-color: white;
    box-shadow: 0px 0px 5px 5px rgba(0, 0, 0, 0.2);
    display: flex;
    justify-content: center;
    flex-direction: column;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    text-align: center;
    margin-top: 12px;
    min-width: 120px;
    opacity: 0;
    padding: 8px;
    visibility: hidden;
    white-space: nowrap;
    transition-timing-function: ease;
    transition-duration: 0.5s;
    transition-property: opacity, visibility;
    transition-delay: 0s, 0s;
    z-index: 20;

    &::before,
    ::after {
      content: '';
      width: 0px;
      height: 0px;
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
    }

    &::before {
      border-bottom: 12px solid rgba(0, 0, 0, 0.07);
      border-left: 12px solid transparent;
      border-right: 12px solid transparent;
      top: -14px;
    }

    &::after {
      border-bottom: 10px solid white;
      border-left: 10px solid transparent;
      border-right: 10px solid transparent;
      top: -10px;
    }

    > a {
      color: $fulutu-black;
      padding: 12px 0;
      transition: $transition-all-ease-fast;

      &:hover {
        color: $fulutu-rose;
      }
    }
  }
}

.progression-bar {
  width: 0%;
  position: absolute;
  height: 4px;
  background: $fulutu-blue;
  left: 0;
  top: 94px;
  z-index: 1;
}
</style>
