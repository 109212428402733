<template>
  <div class="im">
    <div class="im-image" @click="openModal">
      <template v-if="previewImageUrl">
        <img @contextmenu.prevent :src="previewImageUrl + '?q=20'" />
      </template>
      <template v-else>
        <img @contextmenu.prevent src="../assets/missing.png" />
      </template>
      <div class="im-content">
        <div class="im-headline" v-html="content.title"></div>
        <div class="im-subheadline" v-html="content.subtitle"></div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import Richtext from './Richtext';
import { mapGetters, mapMutations } from 'vuex';
import { Swiper, SwiperSlide } from 'swiper/vue';
import { Navigation, Pagination, EffectCoverflow } from 'swiper';
import 'swiper/css';
import 'swiper/css/effect-coverflow';
import 'swiper/css/effect-fade';
import 'swiper/css/navigation';

export default {
  name: 'text-image',
  components: {
    Swiper,
    SwiperSlide,
    Richtext,
  },
  props: {
    content: [Array, Object],
  },
  setup() {
    return {
      modules: [Navigation, Pagination, EffectCoverflow],
    };
  },
  computed: {
    ...mapGetters(['getModalState']),
    previewImageUrl() {
      return (
        this.content?.displayImage?.fields?.file?.url ??
        this.content.media[0]?.fields?.file?.url
      );
    },
  },
  methods: {
    ...mapMutations(['setModalContent', 'setModalState']),

    openModal() {
      this.setModalContent(this.content);
      this.setModalState(true);
    },
  },
};
</script>

<style lang="scss">
@import '@/assets/main.scss';

.im {
  animation: appear 0.25s ease-in;
  margin-bottom: 24px;
  border-radius: 5px;

  &-image {
    cursor: pointer;
    position: relative;
    overflow: hidden;
    border-radius: 5px;
    max-height: 250px;

    img {
      width: 100%;
      max-height: 250px;
      object-fit: cover;
      transition: all 0.5s ease;
    }

    .im-content {
      background: rgba(255, 255, 255, 0.5);
      max-width: calc(100% - 64px);
      padding: 4px 8px;
      position: absolute;
      top: 16px;
      left: 16px;
      transition: left 0.6s, top 0.2s ease, font-size 0.6s ease,
        letter-spacing 0.6s;
    }

    .im-headline,
    .im-subheadline {
      color: $fulutu-white;
      letter-spacing: 0px;
      text-shadow: 2px 2px $fulutu-black;
      transition: font-size 0.25s ease, letter-spacing 0.5s;
    }

    .im-headline {
      font-size: 26px;

      @media (min-width: $md) {
        font-size: 36px;
      }
    }

    .im-subheadline {
      font-size: 20px;

      @media (min-width: $md) {
        font-size: 30px;
      }
    }

    &:hover {
      img {
        transform: scale(1.1);
      }
      .im-headline {
        font-size: 28px;
        letter-spacing: 4px;
      }
      .im-subheadline {
        font-size: 22px;
        letter-spacing: 3px;
      }
    }
  }
}
</style>
